import { RoughNotation } from 'react-rough-notation';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';

const Home = () => {
  const [aboutColor, contactColor] = ['#e5e5e5', '#e5e5e5'];

  const { t } = useTranslation('index');

  return (
    <div className="fade-in min-h-[75vh] flex flex-1 flex-col justify-center px-6 py-10 dark:text-white lg:px-10">
      <h1 className="text-3xl font-bold dark:text-white lg:text-5xl">
        {t('h1.title')}
      </h1>
      <p className="my-2 text-lg lg:my-4 lg:text-2xl">{t('p.jobtitle')}</p>
      <p className="font-light lg:text-xl">
        {t('p.readmore')}
        <Link className="ml-2 mr-2 font-normal text-black" href="/about">
          <RoughNotation
            show
            type="highlight"
            animationDelay={250}
            animationDuration={2000}
            color={aboutColor}
          >
            {t('p.aboutme')}
          </RoughNotation>
        </Link>
        {t('p.or')}
        {/* TODO: replace href with href="/contact" */}
        <Link className="ml-2 font-normal text-black" href="/">
          <RoughNotation
            show
            type="highlight"
            animationDelay={250}
            animationDuration={2000}
            color={contactColor}
          >
            {t('p.contactme')}
          </RoughNotation>
        </Link>
      </p>
    </div>
  );
};

export default Home;
